
    // Do not modify this file by hand!
    // Re-generate this file by running lit-localize

    import {html} from 'lit';
    

    /* eslint-disable no-irregular-whitespace */
    /* eslint-disable @typescript-eslint/no-explicit-any */

    export const templates = {
      'h0e60f0986075c2fd': html`Durch diese Lizenzanfrage kann Tenable Ihnen unter Umständen E-Mail-Mitteilungen zu seinen Produkten und Services senden. Sie können diese Mitteilungen jederzeit abbestellen (Opt-out), indem Sie den entsprechenden Link in der Fußzeile der an Sie gesendeten E-Mails verwenden. Ihre Einstellungen für Tenable-E-Mails können Sie auch auf der <a target="_blank" href="https://info.tenable.com/SubscriptionManagement.html">Subscription Management-Webseite</a> verwalten.`,
'h5d94556d32fdee9e': html`Tenable verarbeitet Ihre personenbezogenen Daten ausschließlich in Übereinstimmung mit seiner <a target="_blank" href="https://www.tenable.com/privacy-policy">Datenschutzrichtlinie</a>.`,
'h97a056af7968d75c': html`Durch die Registrierung für diese Testlizenz kann Tenable Ihnen unter Umständen E-Mail-Mitteilungen zu seinen Produkten und Services senden. Sie können diese Mitteilungen jederzeit abbestellen (Opt-out), indem Sie den entsprechenden Link in der Fußzeile der an Sie gesendeten E-Mails verwenden. Ihre Einstellungen für Tenable-E-Mails können Sie auch auf der <a target="_blank" href="https://info.tenable.com/SubscriptionManagement.html">Subscription Management-Webseite</a> verwalten.`,
'hb2f7081d6bbb9ba0': html`Ich stimme den <a href="https://static.tenable.com/prod_docs/tenable_slas.html">Softwarelizenzvereinbarungen</a> von Tenable zu`,
's09205907b5b56cda': `Nein`,
's0adb3f5e5e594e43': `Kurstitel muss angegeben werden`,
's0f3489ff0bc2e14f': `PDF muss hochgeladen werden`,
's0fbf6dc6a1966408': `Weiter`,
's12e486740e2e33fc': `Name der Organisation muss angegeben werden`,
's150bac76e4f85b3c': `Region muss angegeben werden`,
's15361efa1eef6aee': `Loslegen`,
's1cdeecda0baf365e': `Release`,
's1dda911063939e05': `Ihre Testversion wurde verlängert.`,
's1ed5c670ff346e93': `Kommentar muss angegeben werden`,
's1f5a2beee27d45e7': `Möglicherweise ging ein Kunde davon aus, dass eine Person aus Ihrer Organisation die Durchführung des Scans autorisiert hat. Bitte bestätigen Sie, dass Sie sich mit den entsprechenden Stakeholdern in Verbindung gesetzt und danach erkundigt haben, ob dieser Scan autorisiert wurde.`,
's24233c7c75e9ee19': `Bitte beachten Sie, dass sich Ihr Feedback nur auf die Dokumentation und nicht auf Tenable-Produkte beziehen sollte.`,
's2468b69d10791c82': `Stadt`,
's27707a7616f7f789': `Vorname muss angegeben werden`,
's27cc88818cc46ff6': `Stable`,
's2c57913ffd36d298': `Vorname`,
's2ea2118efa449d32': `PDF des Kurs-Lehrplans oder Dokumentationsunterlagen hochladen`,
's2eb3c51d71b9f608': `Ungültiges Aktivierungscode-Format`,
's30699ee91122500e': `Wir werden uns bezüglich Ihrer Anfrage in Kürze bei Ihnen melden.`,
's31008bbe2c3076e5': `Unternehmensgröße (Mitarbeiter)`,
's32fc186d3b40fb7d': `Name der Organisation`,
's39888fc990ed085e': `Sie müssen sich mit den entsprechenden Stakeholdern in Verbindung setzen, um fortzufahren.`,
's3b8dc2a2dfe650e3': `Sobald Sie dieses Formular an uns übermitteln, werden wir untersuchen, welcher unserer Kunden diese Scan-Aktivität initiiert hat. Bitte geben Sie unten eine E-Mail-Adresse an, über die wir Sie nach Abschluss der Untersuchung kontaktieren können.`,
's404951d60833268e': `Seiten-URL muss angegeben werden`,
's42cbd8dca939a9c7': `Prüfen`,
's4362571c6638089a': `Name der Bildungseinrichtung/des Schulungsprogramms muss angegeben werden`,
's483a20681be190ae': `Kurstitel`,
's4f2540795dacf546': `Überschreitet die maximale Kommentargröße`,
's5209870f843f5cd1': `Ziel-IP-Adressen dürfen nicht leer sein`,
's5855112288e12328': `Titel muss angegeben werden`,
's5b7873343b9dc4eb': `Wann endete der nicht autorisierte Scan-Traffic? (Datum/Uhrzeit in Ihrer lokalen Zeitzone)`,
's5d0cc12e0667faa7': `Name der Bildungseinrichtung/des Schulungsprogramms`,
's5ef6d725815017ce': `Auf welche IP-Adresse(n) zielte der nicht autorisierte Scan ab?`,
's686306cdb839fb8d': `Wird gesendet ...`,
's6b986b4370cf95cf': `Bitte geben Sie die folgenden Informationen an, um ein Konto zu erstellen und Ihre Testversion zu starten.`,
's6b9a88ee6c526aa4': `Quell-IP-Adressen dürfen nicht leer sein`,
's6dcf1b198660d558': `Hat Ihnen diese Seite weitergeholfen?`,
's6faf53a851ae1ef8': `Ungültige E-Mail-Adresse`,
's745d8397b36781a7': `Geben Sie bitte Ihre geschäftliche E-Mail-Adresse an, um eine Testversion zu starten.`,
's76b6c6ef5bc06202': `GA (Allgemeine Verfügbarkeit)`,
's776925a2c81b7a63': `Geschäftliche E-Mail-Adresse`,
's78df1fa22c512d7d': `Kommentar (Optional)`,
's79df2775a8139811': `Zustimmung zu Softwarelizenzvereinbarungen ist erforderlich`,
's7cb4e55c59663b1c': `Unternehmen`,
's80a0e23d9c7eea56': `Adresse muss angegeben werden`,
's81ec7a6847534d5e': `Zurück`,
's85e492de89c8ae2c': `Unternehmensgröße muss angegeben werden`,
's8868de3e06abe230': `E-Mail-Adresse ungültig`,
's8a830d13f8d3298b': `Testversion starten`,
's912d50b0bb095241': `E-Mail-Adresse darf nicht leer sein`,
's95a75a2392af5780': `Nachname`,
's99f110d27e30b289': `Titel`,
's9cf7402fe920f7d1': `Land`,
'sa21690c4800aeb69': `Nützlichkeit der Seite muss bewertet werden`,
'sa4343664d7b15d0c': `PDF des Nonprofit-Status hochladen`,
'sa4cdfd30561e69a1': `Ungültiger Vorname`,
'sa9c6d50e25c88911': `Nachname muss angegeben werden`,
'sae96e6ca5de9220c': `Aktivierungscode darf nicht leer sein`,
'saf84ec86139d349e': `Kommentar`,
'sb37fd819bd638708': `Kommentar hinterlassen ...`,
'sb3d4f79d9d8b71e5': `Senden`,
'sb51270e19988f9bd': `Region`,
'sb7567af23b8f5703': `Vielen Dank!`,
'sb7c9b2995d6869b1': `Nachname muss angegeben werden`,
'sb839a044bb672d70': `Land muss angegeben werden`,
'sbaf0ff2390822c90': `In Ihrem Produkt ist die Testversion von Nessus Expert jetzt aktiviert.`,
'sbd66ac1792782ee6': `Laden Sie Nessus herunter und installieren Sie das Programm, um Ihre Testversion zu starten.`,
'sbde039f6fe3847bf': `Startdatum darf nicht leer sein`,
'sc16e00a7a8b2fde2': `Zurück`,
'sc46caeb9d1bc8fc6': `Übermittlung fehlgeschlagen`,
'sc9446ac9849aa293': `Postleitzahl`,
'scb489a1a173ac3f0': `Ja`,
'scf226d2c20f172c2': `Telefonnummer muss angegeben werden`,
'sd1ae08b28f9defa9': `Enddatum darf nicht leer sein`,
'sd1f44f1a8bc20e67': `E-Mail`,
'sd33e190b8e41dc5f': `Von welcher (welchen) Quell-IP-Adresse(n) ging der nicht autorisierte Scan aus?`,
'sd3d0ca1bec5003d7': `Stadt muss angegeben werden`,
'sda8ea7031a8a7d69': `Vorname muss angegeben werden`,
'sdc79ece8adeaa363': `Ungültiger Nachname`,
'sdcf8c65961c70674': `Ich habe mich mit den entsprechenden Stakeholdern in Verbindung gesetzt und dieser Scan wurde nicht autorisiert.`,
'sde6ffc33e0ca1f9b': `Bitte CIDR-Schreibweise verwenden (z. B.: 0.0.0.0/24 oder 1.1.1.1/32)`,
'sdfb2b12b255de30c': `Kopiert!`,
'se032693d59c82e26': `Aktivierungscode`,
'se1ad99292eaa4000': `Anfrage absenden`,
'se7c56a68f0ecf6e9': `Wird übermittelt ...`,
'se8563c7e9107f391': `Ungültige IP-Adresse`,
'sed7161a6bc9e01f6': `E-Mail-Adresse muss angegeben werden`,
'sedabde95323d0cc2': `E-Mail-Adresse ist ungültig`,
'sf1af788523eecd55': `Jetzt herunterladen`,
'sf1bc83d2509bafcf': `Telefonnummer`,
'sf1d7c751277ec33c': `Vielen Dank für Ihr Feedback!`,
'sf4122b220926be97': `Version`,
'sf820da2caa9be44b': `Wann startete der nicht autorisierte Scan-Traffic? (Datum/Uhrzeit in Ihrer lokalen Zeitzone)`,
'sf9286dfc7b09c4c3': `Gesperrte E-Mail-Adresse / Ungültige E-Mail-Adresse`,
'sfcdd8e672c75b713': `Adresse`,
'sfd76061a1ae701dd': `Unternehmen muss angegeben werden`,
'sff6467521565aa6d': `Produkt muss angegeben werden`,
'sffe2914d4b7c91ee': `Early Access`,
's5f7a4982df278fbe': `Customer Name cannot be blank`,
's77d3ba42477d1e37': `Customer Email cannot be blank`,
's67da547a401b53a2': `Customer Organization cannot be blank`,
's4c672206f89e3a5a': `Output of OS Fingerprint plugin cannot be blank`,
's7e2003487616c0be': `Actual operating system and version running on asset cannot be blank`,
'sd2b571fd3bc03be2': `Product category cannot be blank`,
'sf1e5f5e4fdd3bba0': `Customer Name`,
's23a3125df912b8f9': `Customer Email`,
'se931b3bbed6e76fc': `Customer Organization`,
'scbf29ce484222325': ``,
's5d348d92f624a8d4': `Output of OS Fingerprint plugin`,
'sa425ce5a226eaca0': `Actual operating system and version running on asset`,
's5af6ae0f37cdcebc': `Product category`,
's5c0a9eb7dfb98536': `First Name cannot be blank`,
's02aa50806450b61e': `Last Name cannot be blank`,
'sa39f0be64bdbf3c2': `Job Role cannot be blank`,
's678bad556f8c741c': `Partner ID cannot be blank`,
's73e6604052ffe142': `Company cannot be blank`,
    };
  