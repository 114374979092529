import { configureLocalization } from '@lit/localize';
import { sourceLocale, targetLocales } from './generated';
import * as ar from './generated/locales/ar';
import * as de from './generated/locales/de';
import * as fr from './generated/locales/fr';
import * as es from './generated/locales/es';
import * as esLa from './generated/locales/es-la';
import * as zhCn from './generated/locales/zh-cn';
import * as zhTw from './generated/locales/zh-tw';
import * as jp from './generated/locales/jp';
import * as ptBr from './generated/locales/pt-br';
import * as it from './generated/locales/it';
import * as kr from './generated/locales/kr';

const localizedTemplates = new Map([
  ['ar', ar],
  ['de', de],
  ['fr', fr],
  ['es', es],
  ['es-la', esLa],
  ['zh-cn', zhCn],
  ['zh-tw', zhTw],
  ['jp', jp],
  ['pt-br', ptBr],
  ['it', it],
  ['kr', kr],
]);

export const { getLocale, setLocale } = configureLocalization({
  sourceLocale,
  targetLocales,
  loadLocale: async (locale) => localizedTemplates.get(locale),
});

export const getLocaleByHostname = () => {
  const domains = [
    { locale: 'en', host: 'localhost' },
    { locale: 'de', host: 'de.localhost' },
    { locale: 'es', host: 'es.localhost' },
    { locale: 'es-la', host: 'es-la.localhost' },
    { locale: 'fr', host: 'fr.localhost' },
    { locale: 'jp', host: 'jp.localhost' },
    { locale: 'kr', host: 'kr.localhost' },
    { locale: 'zh-cn', host: 'zh-cn.localhost' },
    { locale: 'zh-tw', host: 'zh-tw.localhost' },
    { locale: 'ar', host: 'ar.tenable.com' },
    { locale: 'ar', host: 'ar-sa-wr4e7rhe-p.sitesync.acolad.com' },
    { locale: 'ar', host: 'ar-sa-wr4e7rhe-c.sitesync.acolad.com' },
    { locale: 'ar', host: 'ar-sa-wr4e7rhe.sitesync.acolad.com' },
    { locale: 'ar', host: 'ar-sa-wr4e7rhe.us.armor.domainverify.net' },
    { locale: 'de', host: 'de.tenable.com' },
    { locale: 'de', host: 'de-de-wr4e7rhe-p.sitesync.acolad.com' },
    { locale: 'de', host: 'de-de-wr4e7rhe-c.sitesync.acolad.com' },
    { locale: 'de', host: 'de-de-wr4e7rhe.sitesync.acolad.com' },
    { locale: 'de', host: 'de-de-wr4e7rhe.us.armor.domainverify.net' },
    { locale: 'de', host: 'docs-de-staging.tenable.com' },
    { locale: 'de', host: 'de.docs.tenable.com' },
    { locale: 'fr', host: 'fr.tenable.com' },
    { locale: 'fr', host: 'fr-fr-wr4e7rhe-p.sitesync.acolad.com' },
    { locale: 'fr', host: 'fr-fr-wr4e7rhe-c.sitesync.acolad.com' },
    { locale: 'fr', host: 'fr-fr-wr4e7rhe.sitesync.acolad.com' },
    { locale: 'fr', host: 'fr-fr-wr4e7rhe.us.armor.domainverify.net' },
    { locale: 'fr', host: 'docs-fr-staging.tenable.com' },
    { locale: 'fr', host: 'fr.docs.tenable.com' },
    { locale: 'es-la', host: 'es.docs.tenable.com' },
    { locale: 'es-la', host: 'docs-es-staging.docs.tenable.com' },
    { locale: 'es-la', host: 'es-la.tenable.com' },
    { locale: 'es-la', host: 'es-mx-wr4e7rhe-p.sitesync.acolad.com' },
    { locale: 'es-la', host: 'es-mx-wr4e7rhe-c.sitesync.acolad.com' },
    { locale: 'es-la', host: 'es-mx-wr4e7rhe.sitesync.acolad.com' },
    { locale: 'es-la', host: 'es-mx-wr4e7rhe.us.armor.domainverify.net' },
    { locale: 'zh-cn', host: 'zh-cn.tenable.com' },
    { locale: 'zh-cn', host: 'www.tenablecloud.cn' },
    { locale: 'zh-cn', host: 'zh-cn-wr4e7rhe-p.sitesync.acolad.com' },
    { locale: 'zh-cn', host: 'zh-cn-wr4e7rhe-c.sitesync.acolad.com' },
    { locale: 'zh-cn', host: 'zh-cn-wr4e7rhe.sitesync.acolad.com' },
    { locale: 'zh-cn', host: 'zh-cn-wr4e7rhe.us.armor.domainverify.net' },
    { locale: 'zh-cn', host: 'docs-zh-cn-staging.tenable.com' },
    { locale: 'zh-cn', host: 'zh-cn.docs.tenable.com' },
    { locale: 'zh-tw', host: 'zh-tw.tenable.com' },
    { locale: 'zh-tw', host: 'zh-tw-wr4e7rhe-p.sitesync.acolad.com' },
    { locale: 'zh-tw', host: 'zh-tw-wr4e7rhe-c.sitesync.acolad.com' },
    { locale: 'zh-tw', host: 'zh-tw-wr4e7rhe.sitesync.acolad.com' },
    { locale: 'zh-tw', host: 'zh-tw-wr4e7rhe.us.armor.domainverify.net' },
    { locale: 'zh-tw', host: 'docs-zh-tw-staging.tenable.com' },
    { locale: 'zh-tw', host: 'zh-tw.docs.tenable.com' },
    { locale: 'jp', host: 'jp.tenable.com' },
    { locale: 'jp', host: 'ja-jp-wr4e7rhe-p.sitesync.acolad.com' },
    { locale: 'jp', host: 'ja-jp-wr4e7rhe-c.sitesync.acolad.com' },
    { locale: 'jp', host: 'ja-jp-wr4e7rhe.sitesync.acolad.com' },
    { locale: 'jp', host: 'ja-jp-wr4e7rhe.us.armor.domainverify.net' },
    { locale: 'jp', host: 'docs-jp-staging.tenable.com' },
    { locale: 'jp', host: 'jp.docs.tenable.com' },
    { locale: 'pt-br', host: 'pt-br.tenable.com' },
    { locale: 'pt-br', host: 'pt-br-wr4e7rhe-p.sitesync.acolad.com' },
    { locale: 'pt-br', host: 'pt-br-wr4e7rhe-c.sitesync.acolad.com' },
    { locale: 'pt-br', host: 'pt-br-wr4e7rhe.sitesync.acolad.com' },
    { locale: 'pt-br', host: 'pt-br-wr4e7rhe.us.armor.domainverify.net' },
    { locale: 'it', host: 'it.tenable.com' },
    { locale: 'it', host: 'it-it-wr4e7rhe-p.sitesync.acolad.com' },
    { locale: 'it', host: 'it-it-wr4e7rhe-c.sitesync.acolad.com' },
    { locale: 'it', host: 'it-it-wr4e7rhe.sitesync.acolad.com' },
    { locale: 'it', host: 'it-it-wr4e7rhe.us.armor.domainverify.net' },
    { locale: 'kr', host: 'kr.tenable.com' },
    { locale: 'kr', host: 'ko-kr-wr4e7rhe-p.sitesync.acolad.com' },
    { locale: 'kr', host: 'ko-kr-wr4e7rhe-c.sitesync.acolad.com' },
    { locale: 'kr', host: 'ko-kr-wr4e7rhe.sitesync.acolad.com' },
    { locale: 'kr', host: 'ko-kr-wr4e7rhe.us.armor.domainverify.net' },
    { locale: 'kr', host: 'docs-kr-staging.tenable.com' },
    { locale: 'kr', host: 'kr.docs.tenable.com' },
  ];

  const domain = domains.find((d) => d.host === (window?.location?.hostname));

  return domain?.locale ?? 'en';
};

export const setLocaleFromHostname = async (locale) => {
  const currentLocale = locale || getLocaleByHostname() || sourceLocale;

  return setLocale(currentLocale);
};
