// Do not modify this file by hand!
// Re-generate this file by running lit-localize.

/**
 * The locale code that templates in this source code are written in.
 */
export const sourceLocale = `en`;

/**
 * The other locale codes that this application is localized into. Sorted
 * lexicographically.
 */
export const targetLocales = [
  `ar`,
  `de`,
  `es`,
  `es-la`,
  `fr`,
  `it`,
  `jp`,
  `kr`,
  `pt-br`,
  `zh-cn`,
  `zh-tw`,
];

/**
 * All valid project locale codes. Sorted lexicographically.
 */
export const allLocales = [
  `ar`,
  `de`,
  `en`,
  `es`,
  `es-la`,
  `fr`,
  `it`,
  `jp`,
  `kr`,
  `pt-br`,
  `zh-cn`,
  `zh-tw`,
];
