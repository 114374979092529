import { css } from 'lit';

export default [
  css`
  /* Input */
  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="tel"],
  input[type="url"],
  input[type="number"],
  input[type="datetime-local"] {
    width: var(--tenable-input-width, 100%);
    border: var(--tenable-input-border, 1px solid #ccc);
    border-radius: var(--tenable-input-border-radius, 3px);
    padding: var(--tenable-input-padding, .5rem);
    display: var(--tenable-input-display, block);
    box-shadow: inset 0 1px 2px #0000000d;
    font: var(--tenable-input-font, inherit);
    line-height: var(--tenable-input-line-height, normal);
    box-sizing:  var(--tenable-input-box-sizing, border-box);
    font-size: var(--tenable-input-font-size, 1rem);
  }

  input:focus {
    border-color: var(--tenable-input-focus-border-color, #86b7fe);
    box-shadow: var(--tenable-input-focus-box-shadow, 0 0 0 .15rem rgba(13,110,253,.25));
    outline: 0;
  }

  input.invalid {
    border-color: var(--tenable-input-invalid-border-color, #e24301);
  }

  input[type="hidden"] {
    display: none;
  }

  input::placeholder {
    color: var(--tenable-input-placeholder-color, #25272a);
    font-weight: var(--tenable-input-placeholder-font-weight, 400);
  }

  /* Input Hides Default Browser Styling */
  input.hide-spinner::-webkit-outer-spin-button,
  input.hide-spinner::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input.hide-spinner[type=number] {
    -moz-appearance: textfield;
  }

  /* Label */
  label {
    margin: var(--tenable-label-margin, 0 0 .25rem);
    display: block;
    font-weight: var(--tenable-label-font-weight, 600);
    color: var(--tenable-label-color, #425363);
  }

  /* Floating Label */
  .form-floating {
    position: relative;
  }

  .form-floating label {
    display: inline-block;
    position: absolute;
    top: var(--tenable-input-padding, .75rem);
    left: var(--tenable-input-padding, .75rem);
    pointer-events: none;
    transition: all .1s ease-in-out;
    color: var(--tenable-input-placeholder-color, var(--tenable-text-color, #425363));
    font-weight: var(--tenable-input-placeholder-font-weight, 400);
  }

  .form-floating input {
    padding: .75rem;
    font-size: 1rem;
  }

  .form-floating input:focus,
  .form-floating input:not(:placeholder-shown) {
    padding: 1.15rem 0.75rem 0.35rem;
  }

  .form-floating input:not(:placeholder-shown) + label,
  .form-floating input:focus + label {
    font-size: .75rem;
    transform: translate(0, -.50rem);
    color: var(--tenable-label-floating-color, #495057);
  }
  `,
];
