import { LitElement, html } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import styles from './styles';

class Input extends LitElement {
  static styles = styles;

  static properties = {
    type: {
      type: String,
      converter: (value) => (['text', 'email', 'password', 'number', 'tel', 'url', 'hidden', 'checkbox', 'datetime-local', 'file'].includes(value) ? value : 'text'),
    },
    name: { type: String },
    id: { type: String },
    placeholder: { type: String },
    min: { type: String },
    max: { type: String },
    maxLength: { type: String, attribute: 'max-length' },
    invalid: { type: Boolean, reflect: true },
    required: { type: Boolean, reflect: true },
    autofocus: { type: Boolean, reflect: true },
    disabled: { type: Boolean, reflect: true },
    readonly: { type: Boolean, reflect: true },
    ariaDescribedBy: { type: String, attribute: 'aria-describedby' },
    ariaLabel: { type: String, attribute: 'aria-label' },
    ariaInvalid: { type: String, attribute: 'aria-invalid' },
    value: { type: String, reflect: true },
    checked: { type: Boolean, reflect: true },
    label: { type: String },
    hideLabel: { type: Boolean },
    labelType: {
      type: String,
      attribute: 'label-type',
      converter: (value) => (['before', 'after', 'around', 'float'].includes(value) ? value : 'before'),
    },
    accept: { type: String },
    multiple: { type: Boolean, reflect: true },
    inputmode: { type: String, reflect: true },
    hideSpinner: { type: Boolean, attribute: 'hide-spinner' },
  };

  constructor() {
    super();
    this.type = 'text';
    this.name = '';
    this.id = undefined;
    this.placeholder = '';
    this.min = undefined;
    this.max = undefined;
    this.maxLength = undefined;
    this.invalid = false;
    this.required = false;
    this.autofocus = false;
    this.disabled = false;
    this.readonly = false;
    this.ariaDescribedBy = '';
    this.ariaLabel = '';
    this.value = '';
    this.checked = false;
    this.label = '';
    this.hideLabel = false;
    this.labelType = 'before';
    this.accept = '';
    this.multiple = false;
    this.inputmode = undefined;
    this.hideSpinner = false;
  }

  #handleChange(event) {
    let { value } = event.target;
    if (this.type === 'checkbox') value = event.target.checked;
    if (this.type === 'file') value = Array.from(event.target.files);

    this.value = value;
    this.dispatchEvent(
      new CustomEvent('input-change', {
        bubbles: true,
        composed: true,
        detail: {
          srcEvent: event,
          name: this.name,
          value,
        },
      }),
    );
  }

  #handleBlur(event) {
    this.dispatchEvent(
      new CustomEvent('input-blur', {
        bubbles: true,
        composed: true,
        detail: {
          srcEvent: event,
          name: this.name,
        },
      }),
    );
  }

  #handleKeyDown(event) {
    this.dispatchEvent(
      new CustomEvent('input-keydown', {
        bubbles: true,
        composed: true,
        detail: {
          srcEvent: event,
        },
      }),
    );
  }

  #handleFocus(event) {
    if (!this.disabled) {
      this.dispatchEvent(
        new CustomEvent('input-focus', {
          bubbles: true,
          composed: true,
          detail: {
            srcEvent: event,
          },
        }),
      );
    }
  }

  #handleMouseDown(event) {
    if (!this.disabled) {
      this.dispatchEvent(
        new CustomEvent('input-mousedown', {
          bubbles: true,
          composed: true,
          detail: {
            srcEvent: event,
          },
        }),
      );
    }
  }

  #handleValueChange() {
    this.dispatchEvent(
      new CustomEvent('input-change', {
        bubbles: true,
        composed: true,
        detail: {
          name: this.name,
          value: this.value,
        },
      }),
    );
  }

  render() {
    this.#handleValueChange();
    const classes = classMap({
      invalid: this.invalid,
      'hide-spinner': this.hideSpinner,
    });

    const input = html`<input
      @blur=${this.#handleBlur}
      @input=${this.#handleChange}
      @focus=${this.#handleFocus}
      @keydown=${this.#handleKeyDown}
      @mousedown=${this.#handleMouseDown}
      class=${classes}
      type=${this.type}
      name=${ifDefined(this.name || undefined)}
      id=${ifDefined(this.id)}
      data-testid=${ifDefined(this.id ?? this.name)}
      ?invalid=${this.invalid}
      ?required=${this.required}
      ?autofocus=${this.autofocus}
      ?disabled=${this.disabled}
      ?readonly=${this.readOnly}
      aria-describedby=${this.ariaDescribedBy}
      aria-label=${this.ariaLabel}
      placeholder=${(this.hideLabel) ? this.label : this.placeholder}
      min=${ifDefined(this.min)}
      max=${ifDefined(this.max)}
      maxlength=${ifDefined(this.maxLength)}
      .value=${this.value}
      .checked=${this.checked}
      accept=${this.accept}
      .multiple=${this.multiple}
      inputmode=${ifDefined(this.inputmode)}
    >`;

    if (!this.label || this.hideLabel) return html`${input}`;

    if (this.labelType === 'float') {
      return html`
        <div class="form-floating">
          ${input}
          <label for=${this.name}>${this.label}</label>
        </div>
      `;
    }

    if (this.labelType === 'before') return html`<label for=${this.name}>${this.label}</label>${input}`;

    if (this.labelType === 'around') return html`<label for=${this.name}>${this.label}${input}</label>`;

    if (this.labelType === 'after') return html`<label for=${this.name}>${input}${this.label}</label>`;

    return '';
  }
}
customElements.define('tenable-input', Input);
