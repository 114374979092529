import { LitElement, html } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import styles from './styles';

class Select extends LitElement {
  static styles = styles;

  static properties = {
    type: {
      type: String,
      converter: (value) => (['text', 'email', 'password', 'number', 'tel', 'url', 'hidden', 'checkbox'].includes(value) ? value : 'text'),
    },
    name: { type: String },
    id: { type: String },
    invalid: { type: Boolean, reflect: true },
    required: { type: Boolean, reflect: true },
    autofocus: { type: Boolean, reflect: true },
    disabled: { type: Boolean, reflect: true },
    ariaDescribedBy: { type: String, attribute: 'aria-describedby' },
    ariaLabel: { type: String, attribute: 'aria-label' },
    ariaInvalid: { type: String, attribute: 'aria-invalid' },
    value: { type: String, reflect: true },
    checked: { type: Boolean, reflect: true },
    label: { type: String },
    hideLabel: { type: Boolean },
    labelType: {
      type: String,
      attribute: 'label-type',
      converter: (value) => (['before', 'after', 'around'].includes(value) ? value : 'before'),
    },
    options: { type: Array },
    multiple: { type: Boolean, reflect: true },
    values: {
      reflect: true,
      converter: {
        fromAttribute: (value) => value.split(','),
        toAttribute: (value) => String(value.join()),
      },
    },
  };

  constructor() {
    super();
    this.type = 'text';
    this.name = '';
    this.id = undefined;
    this.min = undefined;
    this.max = undefined;
    this.maxLength = undefined;
    this.invalid = false;
    this.required = false;
    this.autofocus = false;
    this.disabled = false;
    this.readonly = false;
    this.ariaDescribedBy = '';
    this.ariaLabel = '';
    this.value = '';
    this.checked = false;
    this.label = '';
    this.hideLabel = false;
    this.labelType = 'before';
    this.options = [];
    this.multiple = false;
    this.values = [];
  }

  #handleChange(event) {
    let { value } = event.target;
    let values = [];

    if (this.multiple) {
      values = Array.prototype.slice.call(event.target.querySelectorAll('option:checked'), 0).map((v) => v.value);
      value = values.join();
    }

    this.value = value;
    this.values = values;
    this.dispatchEvent(
      new CustomEvent('input-change', {
        bubbles: true,
        composed: true,
        detail: {
          srcEvent: event,
          name: this.name,
          value: this.multiple ? this.values : this.value,
        },
      }),
    );
  }

  #handleBlur(event) {
    this.dispatchEvent(
      new CustomEvent('input-blur', {
        bubbles: true,
        composed: true,
        detail: {
          srcEvent: event,
          name: this.name,
        },
      }),
    );
  }

  #handleKeyDown(event) {
    this.dispatchEvent(
      new CustomEvent('input-keydown', {
        bubbles: true,
        composed: true,
        detail: {
          srcEvent: event,
        },
      }),
    );
  }

  #handleFocus(event) {
    if (!this.disabled) {
      this.dispatchEvent(
        new CustomEvent('input-focus', {
          bubbles: true,
          composed: true,
          detail: {
            srcEvent: event,
          },
        }),
      );
    }
  }

  #handleMouseDown(event) {
    if (!this.disabled) {
      this.dispatchEvent(
        new CustomEvent('input-mousedown', {
          bubbles: true,
          composed: true,
          detail: {
            srcEvent: event,
          },
        }),
      );
    }
  }

  #handleValueChange() {
    this.dispatchEvent(
      new CustomEvent('input-change', {
        bubbles: true,
        composed: true,
        detail: {
          name: this.name,
          value: this.multiple ? this.values : this.value,
        },
      }),
    );
  }

  render() {
    this.#handleValueChange();

    const selectedOption = this.options.find((option) => option.value === this.value);
    const input = html`
      <select
        @blur=${this.#handleBlur}
        @input=${this.#handleChange}
        @focus=${this.#handleFocus}
        @keydown=${this.#handleKeyDown}
        @mousedown=${this.#handleMouseDown}
        class=${classMap({ invalid: this.invalid, placeholder: selectedOption?.placeholder === true })}
        name=${ifDefined(this.name || undefined)}
        id=${ifDefined(this.id)}
        data-testid=${ifDefined(this.id ?? this.name)}
        ?invalid=${this.invalid}
        ?required=${this.required}
        ?autofocus=${this.autofocus}
        ?disabled=${this.disabled}
        ?multiple=${this.multiple}
        aria-describedby=${this.ariaDescribedBy}
        aria-label=${this.ariaLabel}
      >
        ${this.options.map((option) => html`
          <option value=${option.value} ?selected=${this.multiple ? this.values.includes(option.value) : option.value === this.value} ?disabled=${option.disabled}>
            ${this.hideLabel ? `${this.label}: ${option.label}` : option.label}
          </option>
        `)}
      </select>
  `;

    if (!this.label || this.hideLabel) return html`${input}`;

    if (this.labelType === 'before') return html`<label for=${this.name}>${this.label}</label>${input}`;

    if (this.labelType === 'around') return html`<label for=${this.name}>${this.label}${input}</label>`;

    if (this.labelType === 'after') return html`<label for=${this.name}>${input}${this.label}</label>`;

    return '';
  }
}
customElements.define('tenable-select', Select);
